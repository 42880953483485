<!-- Header -->
<header id="header" class="header">
  <div class="header-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-5">
          <img src="assets/img/logo_blanco.svg" alt="VengaCanada" class="img-fluid">
          <div class="text-container">
            <p class="p-large">Asesoria 100% personalizada mediante conferencia
            </p>
            <a class="btn-solid-lg page-scroll" href="https://calendly.com/vengacanada">AGENDA TU CITA AHORA</a>
          </div> <!-- end of text-container -->
        </div> <!-- end of col -->
        <div class="col-lg-6 col-xl-7">
          <div class="image-container">
            <div class="img-wrapper">
              <img src="/assets/img/reclutadora_perfil.jpg" class="figure-img img-fluid rounded profile"
                alt="VengaCanada Asesora">


            </div> <!-- end of img-wrapper -->
          </div> <!-- end of image-container -->
        </div> <!-- end of col -->
      </div> <!-- end of row -->
    </div> <!-- end of container -->
  </div> <!-- end of header-content -->
</header> <!-- end of header -->
<svg class="header-frame" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
  viewBox="0 0 1920 310">
  <defs>
    <style>
      .cls-1 {
        fill: #ff0000;
      }

    </style>
  </defs>
  <title>header-frame</title>
  <path class="cls-1"
    d="M0,283.054c22.75,12.98,53.1,15.2,70.635,14.808,92.115-2.077,238.3-79.9,354.895-79.938,59.97-.019,106.17,18.059,141.58,34,47.778,21.511,47.778,21.511,90,38.938,28.418,11.731,85.344,26.169,152.992,17.971,68.127-8.255,115.933-34.963,166.492-67.393,37.467-24.032,148.6-112.008,171.753-127.963,27.951-19.26,87.771-81.155,180.71-89.341,72.016-6.343,105.479,12.388,157.434,35.467,69.73,30.976,168.93,92.28,256.514,89.405,100.992-3.315,140.276-41.7,177-64.9V0.24H0V283.054Z" />
</svg>
<!-- end of header -->



<!-- Details -->
<div id="details" class="basic-1">
  <div class="container">
    <div class="row">
      <div id="description" class="cards-1">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title">PASOS A SEGUIR</div>
              <h2 class="h2-heading">Sigue estos pasos para Estudiar en Canada</h2>
            </div> <!-- end of col -->
          </div> <!-- end of row -->
          <div class="row">
            <div class="col-lg-12">

              <!-- Card -->
              <div class="card">
                <div class="card-body justify-text">
                  <h5 class="card-title text-danger">Agenda Tu Cita</h5>
                  <p>Escoja el dia y la hora disponibles en la plataforma de Calendly nuestro pago es 100% seguro y
                    confiable</p>
                </div>
              </div>
              <!-- end of card -->

              <!-- Card -->
              <div class="card">
                <div class="card-body justify-text">
                  <h5 class="card-title text-danger">Recibe tu Asesoria</h5>
                  <p>Recibe una Asesoria personalizada con nuestra asesora educativa Jimena Cardona experta en brindar
                    opciones de estudio en Canada</p>
                </div>
              </div>
              <!-- end of card -->

              <!-- Card -->
              <div class="card">
                <div class="card-body justify-text">
                  <h5 class="card-title text-danger">Elige Donde Quieres Estudiar</h5>
                  <p>Luego de tener tu cita recibiras un informe 100% personalizado sobre las opciones disponibles de
                    acuerdo a tu perfil de esta manera podras seleccionar cual es la que mas te agrada</p>
                </div>
              </div>

              <!-- Card -->
              <div class="card">
                <div class="card-body justify-text">
                  <h5 class="card-title text-danger">Visas e inmigración</h5>
                  <p>
                    Si necesitas ayuda para solicitar tu visa, te ponemos en contacto con Immigrative.ca para que con su
                    experiencia se hagan cargo de su situación. Somos asesores educativos y te ayudamos a escoger el
                    mejor programa académico que mejor se ajuste a
                    tu perfil, tenemos convenio con 12 mil programas académicos en todas las provincias de Canadá.
                  </p>
                </div>
              </div>
              <!-- end of card -->

            </div> <!-- end of col -->
          </div> <!-- end of row -->
        </div> <!-- end of container -->
      </div>

    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="text-container justify-text">
          <h2>Es hora de Construir tu futuro y empezar a luchar por tus sueños.</h2>
          <h4 class="text-danger">Estudia en Canadá</h4>
          <ul>
            <li *ngFor="let x of data">
              {{x.title}}
            </li>
          </ul>

          <a class="btn-solid-reg page-scroll" href="https://calendly.com/vengacanada" target="_blank">AGENDA TU CITA
            YA</a>
        </div> <!-- end of text-container -->
      </div> <!-- end of col -->
      <div class="col-lg-6">
        <!-- Calendly inline widget begin -->
        <div class="calendly-inline-widget" data-url="https://calendly.com/vengacanada?primary_color=ff0000"
          style="min-width:320px;height:50vh;"></div>

        <!-- Calendly inline widget end -->

      </div> <!-- end of col -->
    </div> <!-- end of row -->
    <div id="details" class="basic-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="image-container">
              <img class="img-fluid rounded" src="/assets/img/city.jpg" alt="alternative">
            </div> <!-- end of image-container -->
          </div> <!-- end of col -->
          <div class="col-lg-6 mt-4">
            <div class="text-container justify-text">
              <h2>Estudia en Canadá y Cumple todos tus Sueños</h2>
              <p>Canada es uno de los mejores paises del mundo para estudiar,trabajar y vivir. Cada año mas de 350mil
                personas llegan a este pais. Muchos de ellos como estudiantes Internacionales , por esta razon nosotros
                en vengacanada se especializa en asesorar
                y guiar a los soñadores que desean venir a Canada como estudiantes y disfrutar de todas las
                oportunidades.
              </p>
              <p>
              </p>
              <!-- <ul class="list-unstyled li-space-lg">
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Keep your customers happy and loyal based on technology</div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Understand customers and meet their requirements</div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Targeted client base with Tivo's efficient technology</div>
                </li>
              </ul> -->
              <a class="btn-solid-reg page-scroll" href="https://calendly.com/vengacanada" target="_blank">AGENDA TU
                CITA YA</a>
            </div> <!-- end of text-container -->
          </div> <!-- end of col-->
        </div> <!-- end of row -->
      </div> <!-- end of container -->
    </div>
    <div class="row">
      <div class="basic-2 bg-gray">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 p-3">
              <h3>Visita Mi Canal de Youtube Nelson Reyes y enterate de todas las cosas cotidianas que se viven en
                Canada</h3>
            </div>
            <div class="col-sm-12 d-flex flex-row justify-content-center">
              <!-- Video Preview -->
              <div class="image-container">
                <div class="video-wrapper">
                  <a class="popup-youtube" href="https://www.youtube.com/watch?v=kXDNKHmfsf8" data-effect="fadeIn"
                    target="_blank">
                    <img class="img-fluid" src="/assets/img/nelson_reyes.jpeg" alt="alternative">
                    <span class="video-play-button">
                      <span></span>
                    </span>
                  </a>
                </div> <!-- end of video-wrapper -->
              </div> <!-- end of image-container -->
              <!-- end of video preview -->

              <!-- <div class="p-heading">What better way to show off Tivo marketing automation saas app than presenting you some great situations of each module and tool available to users in a video</div> -->
            </div> <!-- end of col -->
          </div> <!-- end of row -->
        </div> <!-- end of container -->
      </div>

    </div>
  </div> <!-- end of container -->
</div> <!-- end of basic-1 -->
<!-- end of details -->
<!-- Footer -->
<svg class="footer-frame" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
  viewBox="0 0 1920 79">
  <defs>
    <style>
      .cls-2 {
        fill: #ff0000;
      }

    </style>
  </defs>
  <title>footer-frame</title>
  <path class="cls-2"
    d="M0,72.427C143,12.138,255.5,4.577,328.644,7.943c147.721,6.8,183.881,60.242,320.83,53.737,143-6.793,167.826-68.128,293-60.9,109.095,6.3,115.68,54.364,225.251,57.319,113.58,3.064,138.8-47.711,251.189-41.8,104.012,5.474,109.713,50.4,197.369,46.572,89.549-3.91,124.375-52.563,227.622-50.155A338.646,338.646,0,0,1,1920,23.467V79.75H0V72.427Z"
    transform="translate(0 -0.188)" />
</svg>
<div class="footer">
  <div class="container">
    <div class="row">

    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of footer -->
<!-- end of footer -->


<!-- Copyright -->
<div class="copyright">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <p class="p-small">Derechos Reservados vengacanada.com</p>
      </div> <!-- end of col -->
    </div> <!-- enf of row -->
  </div> <!-- end of container -->
</div> <!-- end of copyright -->
<!-- end of copyright -->
