import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  data = [
    {
      title: '60 minutos de entrevista con el reclutador',
    },
    {
      title: 'Análisis de su perfil para determinar su mejor camino de estudios',
    },
    {
      title: 'Preselección de múltiples instituciones educativas',
    },
    {
      title: 'Búsqueda de un Pathway acorde a tu nivel de inglés o francés',
    },
    {
      title: 'Cálculo de gastos generales como futuro estudiante en canada',
    }
  ];

  form: FormGroup;
  constructor() {
    this.form = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      descripcion: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {}
}
